import { useState,useRef,useEffect } from "react";
import emailjs from '@emailjs/browser';
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapPin, Phone, Mail } from 'lucide-react'; 
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaYoutube } from 'react-icons/fa'; // Import ikon dari react-icons
import { message as messages,Spin } from 'antd';

// Tambahkan icon leaflet untuk marker
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [messageApi, contextHolder] = messages.useMessage();
  const [loading, setLoading] = useState(false);
  const locationRef = useRef(null);

  // Use effect to set up the Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible'); // Add the class when in view
        } else {
          entry.target.classList.remove('visible'); // Remove class when out of view
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust threshold as necessary
    });

    const heading = locationRef.current; // Get the heading element
    if (heading) {
      observer.observe(heading); // Observe the heading
    }

    return () => {
      if (heading) {
        observer.unobserve(heading); // Clean up the observer on component unmount
      }
    };
  }, []);

  const form = useRef();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  
  const clearState = () => setState({ ...initialState });
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const templateParams = {
      name,
      email,
      message
    };
  
    try {
      // First, test the server connection
      const testResponse = await fetch('http://localhost:3001/test', {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
  
      if (!testResponse.ok) {
        throw new Error('Server connection test failed');
      }
  
      // If test passes, send the email
      const response = await fetch('http://localhost:3001/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(templateParams)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to send message');
      }
  
      const result = await response.json();
      messages.success('Your message has been sent successfully!');
      clearState();
      e.target.reset();
  
    } catch (error) {
      console.error('Error:', error);
      messages.error(error.message || 'Failed to send message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
    
 
  


  // Koordinat alamat
  const position = [-6.2311785, 106.9022103];

  return (
    <>
    <div id="Kontak">
      <div className="contact-container">
        <div className="form-section">
          <h2>Get in touch</h2>
          <p>We are here for you! How can we help?</p>
          <form className="contact-form" ref={form} onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              placeholder="Enter your name"
              name="name"
              value={name}
              onChange={handleChange}
              required
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              name="email"
              value={email}
              onChange={handleChange}
              required
            />
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              placeholder="Enter your message"
              name="message"
              value={message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" disabled={loading}>
              {loading ? <Spin size="small" /> : 'Submit'}
            </button>
          </form>
        </div>

        <div className="contact-info">
          <div className="contact-image">
            <img src="img/contact.svg" alt="Contact Us" />
          </div>
        </div>
      </div>

     {/* Peta dengan Leaflet */}
<div className='leaflet-container'>
<h2 className="contact-title fade-in-left" ref={locationRef}>Our Location</h2>
  <MapContainer center={[-6.232167, 106.888528]} zoom={16} scrollWheelZoom={false}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    />
    <Marker position={[-6.232653337852412, 106.88904919325402]}>
      <Popup>
        Jl. Cakra Wijaya VIII No.1, RT.1/RW.12, Cipinang Muara, Kecamatan Jatinegara, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13420
      </Popup>
    </Marker>
  </MapContainer>
</div>


      
    </div>
    <footer className="footer">
    <div className="footer-container">
      <div className="footer-section">
        <h3>Who we are!</h3>
        <p>
          Aspersindo provides a security printing platform. Synergia security printing companies in Indonesia within a framework of fair competition to become a trusted partner for security document solutions.
        </p>
      </div>
      <div className="footer-section">
        <h3>Popular Posts</h3>
        <ul>
          <li><a href="#about">About us</a></li>
          <li><a href="#team">Members</a></li>
          <li><a href="#gallery">Gallery</a></li>
          <li><a href="#Kontak">Contact us</a></li>
        </ul>
      </div>
      <div className="footer-section">
        <h3>Contact Us</h3>
        <p>
          <a
            href="https://maps.app.goo.gl/zw7nqDxoXqM7DX2B9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MapPin size={18} style={{ marginRight: "8px" }} />
            JL Cakra Wijaya VIII Blok T No.9, RT5/ RW12, Cipinang Muara, Jatinegara, Jakarta Timur, Jakarta 13420 Indonesia
          </a>
        </p>
        <p>
          <a
            href="https://wa.me/6281818190888"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Phone size={18} style={{ marginRight: "8px" }} />
            +62 818 1819 0888
          </a>
        </p>
        <p>
          <a
            href="mailto:aspersindo@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Mail size={18} style={{ marginRight: "8px" }} />
            aspersindo@gmail.com
          </a>
        </p>
      </div>
      <div className="footer-section">
        {/* <h3>Social</h3>
        <div className="social-icons">
            <FaFacebookF size={18} style={{ marginRight: "10px" }} />
            <FaTwitter size={18} style={{ marginRight: "10px" }} />
            <FaInstagram size={18} style={{ marginRight: "10px" }} />
            <FaYoutube size={18} style={{ marginRight: "10px" }} />
        </div> */}
      </div>
    </div>
    <div className="copyright">
      <p>All right reserved. 2024 ASPERSINDO</p>
    </div>
  </footer>
  </>
  );
};
