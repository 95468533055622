import React, { useRef } from "react";
import Slider from "react-slick"; // Import Slick Slider
import "slick-carousel/slick/slick.css"; // Import Slick CSS
import "slick-carousel/slick/slick-theme.css"; // Import Slick theme CSS
import { MapPin, Mail, Phone, Globe } from 'lucide-react';

export const Team = () => {
  const sliderRef = useRef(null);

  const images = [
    { img: "img/pt-1.png", name: "PT. Swadharma Eragrafindo Sarana" },
    { img: "img/pt-2.png", name: "PT. Puri Panca Puji Bangun" },
    { img: "img/pt-3.png", name: "PT. Balai Pustaka" },
    { img: "img/pt-4.png", name: "PT. Example" },
    { img: "img/pt-1.png", name: "PT. Swadharma Eragrafindo Sarana" },
    { img: "img/pt-2.png", name: "PT. Puri Panca Puji Bangun" },
    { img: "img/pt-3.png", name: "PT. Balai Pustaka" },
    { img: "img/pt-4.png", name: "PT. Example" },
    { img: "img/pt-1.png", name: "PT. Swadharma Eragrafindo Sarana" },
    { img: "img/pt-2.png", name: "PT. Puri Panca Puji Bangun" },
    { img: "img/pt-3.png", name: "PT. Balai Pustaka" },
    { img: "img/pt-4.png", name: "PT. Example" },
    { img: "img/pt-1.png", name: "PT. Swadharma Eragrafindo Sarana" },
    { img: "img/pt-2.png", name: "PT. Puri Panca Puji Bangun" },
    { img: "img/pt-3.png", name: "PT. Balai Pustaka" },
    { img: "img/pt-4.png", name: "PT. Example12" },
  ];

  const companies = [
    {
      name: "PT. ARIA MULTI GRAPHIA",
      phone: "(021) 79190533 / (021) 7940675",
      email: "amgjkt@gmail.com",
      address: "Jl. Kiaracondong No.117, Kel. Babakan Surabaya, Kec. Kiaracondong Bandung",
      image: "img/logo/member-1.png"
    },
    {
      name: "PT. ARIDAS KARYA SATRIA",
      phone: "(0281) 631100 / (0281) 632100",
      email: "aks_secprint@yahoo.co.id",
      address: "Jl. Raya Bojongsari, RT.006/RW.008, Bojongsari I, Bojongsari, Kec. Kembaran, Kabupaten Banyumas, Jawa Tengah 53182",
      image: "img/logo/member-2.png",
      website: "https://aridasgroup.com/"
    },
    {
      name: "PT. BETAWIMAS CEMERLANG",
      phone: "(021) 42093334 / (021) 4257622",
      email: "bmctdr@gmail.com",
      address: "Jl. Garuda No. 80 L Kemayoran – Jakarta 10620",
      image: "img/logo/member-3.png",
      website: "https://www.betawimas.com/"
    },
    {
      name: "PT. CARDSINDO TIGA PERKASA",
      phone: "(021) 59370576",
      email: "sales@cardsindo.com",
      address: "Kawasan Industri Mekar Jaya, Jl. Mekar Jaya No. 121, Sepatan Tangerang – Indonesia 15520",
      image: "img/logo/member-4.png",
      website: "https://www.cardsindo.com/"
    },
    {
      name: "PT. CIPTA SRIGATI LESTARI",
      phone: "(021) 7181852 / (021) 7181854",
      email: "sy.rijal@cipta.com / info@cipta.com",
      address: "Plaza Simatupang, Lantai 2. Jl. Tahi Bonar Simatupang Raya No.1, RT.2/RW.17, Pd. Pinang, Kec. Kby. Lama, Kota Jakarta Selatan, DKI Jakarta 12310",
      image: "img/logo/member-5.png",
      website: "https://www.cslgroup.co.id/contact.html"
    },
    {
      name: "INDUK KOPERASI KEPOLISIAN NEGARA REPUBLIK INDONESIA (INKOPPOL)",
      phone: "(021) 3915890",
      email: "sekretariat.pusat@inkoppol.co.id",
      address: "Jl. Tambak No.2 – Jakarta Pusat 10320",
      image: "img/logo/member-6.png",
      website: "https://inkoppol.co.id/"
    },
    {
      name: "PT. JASUINDO TIGA PERKASA",
      phone: "(031) 8910919 / (031) 8910640",
      email: "marketing@jasuindo.com",
      address: "Jalan Lingkar Timur No. km.1 Gesing, Banjarsari, Kec. Buduran Kab. Sidoarjo, Jawa Timur 61252",
      image: "img/logo/member-7.png",
      website: "https://jasuindo.com/"
    },
    {
      name: "PT. KARSA WIRA UTAMA",
      phone: "(021) 5918106 / (021) 5707875",
      email: "karatama1kwu@yahoo.com",
      address: "Jl. Pajajaran No. 10, Kel. Gandasari, Kec. Jatiuwung – Tangerang 15137",
      image: "img/logo/member-8.png"
    },
    {
      name: "PT. LENTERA BERKAH ABADI",
      phone: "(021) 75813791",
      address: "Jl. Pangeran Antasari No. 67 (Ruko Baverly Blok J), Cilandak Barat, Jakarta Selatan",
      image: "img/logo/member-9.png"
    },
    {
      name: "PT. MECOSUPRIN GRAFIA",
      phone: "(021) 4750001 / (021) 4758571",
      email: "mecosuprin@yahoo.co.id / sales@mecosuprin.com",
      address: "Jl. Pam Kp Baru Rt/Rw 011/07, Kel. Cakung Barat, Kec. Cakung, Jakarta 13910",
      image: "img/logo/member-10.png",
      website: "https://www.mecosuprin.co.id/"
    },
    {
      name: "PT. MULIAFORM GRAFIDO",
      phone: "(024) 7621810",
      email: "muliaformgrafido@muliaprintinggroup.com",
      address: "Kawasan Industri Candi Blok 20a No.20, Ngaliyan, Semarang",
      image: "img/logo/member-11.png",
      website: "https://www.muliaformgrafido.com/"
    },
    {
      name: "PT. PELITA TEKNOLOGI GLOBAL",
      phone: "(021) 7694639",
      email: "info@pelitateknologi.com",
      address: "Gedung Rpx Centre 7 Lt. 8, Jl. Ciputat Raya No. 99, Rt. 005 Rw. 008, Pondok Pinang, Kebayoran Lama",
      image: "img/logo/member-12.png",
      website: "https://www.pelitateknologi.com/"
    },
    {
      name: "PT. PERCETAKAN BALI",
      phone: "(0361) 234723",
      email: "group@percetakanbali.co.id",
      address: "Jl. Gajah Mada I/1, Denpasar - Bali",
      image: "img/logo/member-13.png",
      website: "https://percetakanbali.co.id/"
    },
    {
      name: "PERUM PERCETAKAN NEGARA RI",
      phone: "(021) 4221701 / (021) 4221705",
      email: "humas@pnri.co.id",
      address: "Jl. Percetakan Negara No. 21 Johar Baru 10560",
      image: "img/logo/member-14.png",
      website: "https://pnri.co.id/id/"
    },
    {
      name: "PERUM PERURI",
      phone: "(021) 7395000",
      email: "depmarketing@peruri.co.id / contact@peruri.co.id",
      address: "Jl. Palatehan No. 4, Blok K-V, Kebayoran Baru, Jakarta 12160",
      image: "img/logo/member-15.png",
      website: "https://www.peruri.co.id/"
    },
    {
      name: "PT. PERURI WIRA TIMUR",
      phone: "(031) 8438843",
      email: "depjul@perurisecprint.co.id",
      address: "Jl. Ahmad Yani No 119 Surabaya, Jawa Timur",
      image: "img/logo/member-16.png",
      website: "https://perurisecprint.co.id/"
    },
    {
      name: "PT. PURA BARUTAMA",
      phone: "(0291) 437121 / (0291) 444361",
      email: "marketing@puragroup.com",
      address: "Jl. AKBP Agil Kusumadya No. 203 Kudus",
      image: "img/logo/member-17.png",
      website: "https://www.puragroup.com"
    },
    {
      name: "PT. RUMAH CETAK KITA",
      phone: "(031) 99780076",
      email: "rck.sby1@gmail.com",
      address: "Perg. Tritan Blok C17-C18 Taman Sidoarjo, Surabaya",
      image: "img/logo/member-18.png",
      website: "https://rcksurabaya.punia.id"
    },
    {
      name: "PT. SARANA PANCAKARYA NUSA",
      phone: "(021) 5893371",
      email: "office@spkn.co.id",
      address: "Jl. Terusan Kopo No. 633-641 Km. 13,4 Katapang, Bandung 40971",
      image: "img/logo/member-19.png",
      website: "https://spkn.co.id/"
    },
    {
      name: "PT. SINAR MURNI INDOPRINTING",
      phone: "(031) 8910121 / (031) 8910122",
      address: "Jl. Sedati No. 37 Gedangan, Sidoarjo – Jawa Timur 61254",
      image: "img/logo/member-20.png",
      website: "https://ptsmip.com/"
    },
    {
      name: "PT. SOLO MURNI",
      phone: "(0271) 711044 / (021) 46833789",
      email: "kiky@kiky.com",
      address: "Jl. A. Yani No. 378, Solo 57143, Central Java - Indonesia",
      image: "img/logo/member-21.png",
      website: "https://www.kiky.com/"
    },
    {
      name: "PT. STACOPA RAYA",
      phone: "(021) 6194085 / (021) 6321618",
      email: "stacopa@gmail.com",
      address: "Jalan Kyai Haji Moh Mansyur. no 11, B16 DKI Jakarta, Indonesia",
      image: "img/logo/member-22.png",
      website: "https://stacopa.com"
    },
    {
      name: "PT. SUMBER CAKUNG",
      phone: "(021) 7512355 / (021) 7503403",
      email: "antonparton@yahoo.co.id",
      address: "Jl. H. Nawi Raya No. 29, Gandaria Selatan, Cilandak, Jakarta 12420",
      image: "img/logo/member-23.png",
      website: "https://PT._sumber_cakung.indonetwork.co.id/"
    },
    {
      name: "CV. SUMBER SARANA PRIMA",
      phone: "(0717) 436360 / (0717) 423110",
      email: "sspprinting1pkp@gmail.com",
      address: "Jl. Letkol H.S. Salam No. 49 Kel. Melintang, Pangkalpinang",
      image: "img/logo/member-24.png"
    },
    {
      name: "PT. SWADHARMA ERAGRAFINDO SARANA",
      phone: "(021) 29303132",
      email: "ses.securityprinting@gmail.com",
      address: "Jl. Raya Cileungsi – Jonggol Km.10, Kp. Tengah No. 3, Cipeucang – Cileungsi, Kab. Bogor, Jawa Barat",
      image: "img/logo/member-25.png"
    },
    {
      name: "PT. TRISAKTI MUSTIKA GRAPHIKA",
      phone: "(024) 7603555",
      email: "info@trisakti.co.id",
      address: "Jl. Kayu Putih Utara VI No.174, RT.2/RW.8, Pulo Gadung, Kec. Pulo Gadung, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13260",
      image: "img/logo/member-26.png",
      website: "https://trisakti.co.id/"
    },
    {
      name: "PT. WAHYU ABADI",
      phone: "(021) 4610072",
      email: "inquiries@wahyuabadi.co.id",
      address: "Jalan Angsana Raya Blok L2 No.1 Dan 17 Delta Silicon 1 Lippo Cikarang - Bekasi",
      image: "img/logo/member-27.png",
      website: "https://wahyuabadi.co.id/"
    },
    {
      name: "PT. WAHYU KARTUMASINDO INTERNATIONAL",
      phone: "(021) 5153132",
      email: "support@wahyu.com",
      address: "Equity Tower, Lt. 15, Suite 15B, Jl. Jend. Sudirman, Kav 52-53 Jakarta, 12190",
      image: "img/logo/member-28.png",
      website: "https://wahyukartu.com/"
    },
    {
      name: "PT. WARNA MUKTI GRAFIKA",
      phone: "(0271) 784724",
      email: "warnamuktigrafika@gmail.com",
      address: "Jl. Embarkasi Haji Km. I Gagaksipat, Ngemplak, Boyolali – Jawa Tengah",
      image: "img/logo/member-29.png",
      website: "https://warnamuktigrafika.co.id/"
    }
  ];
  // Slider settings for React Slick
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div id="team" className="text-center">
       <div className="container">
      <h1 className="title">Our Member</h1>
      <div className="company-list">
        {companies.map((company, index) => (
          <div key={index} className={`company-card ${index % 2 === 0 ? 'even' : 'odd'}`}>
            <div className="company-logo">
              <img src={company.image} alt={company.name} />
              {/* <h2>{company.name}</h2> */}
            </div>
            <div className="company-details">
              <h1>{company.name}</h1>
              <p><MapPin size={18} className="icon" /> {company.address}</p>
              {company.email && <p><Mail size={18} className="icon" /> {company.email}</p>}
              {company.phone && <p><Phone size={18} className="icon" /> {company.phone}</p>}
              {company.website && (
                <p>
                  <Globe size={18} className="icon" />
                  <a href={company.website} target="_blank" rel="noopener noreferrer">
                    {company.website}
                  </a>
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
       <div className="container">
       <h1 className="members-title">Company Location</h1>
        <img className="img-responsive" src="img/locMember.svg" alt="Location Members"/>
      </div>
      <div className="additional-images">
        <img src="img/iklan-2.svg" alt="Advertisement 2" className="ad-img ad-img-2" />
      </div>
    </div>
  );
};
